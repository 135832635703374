export const initialState = {
  finalAmount: 0,
  amount: 0,
  taxes: 0,
  addonamount: 0,
  addontaxes: 0,
  roomStays: [],
  addOns: [],
  userData: {},
  hotelId: 0,
  apiParameters: {},
  flexibleToggle: false,
  finalBookingAmount: {},
  roomTypeData: [],
  bookingData: {},
  hotelData: {},
  finalBookingData: {},
  bookingId: "",
  promoCodeData: undefined,
  promoCode: "",
  payAtHotel: true,
  clientSecret: "",
  bookingPayloadId: "",
  bookingSource: "",
  customPgInfo: {},
  checkInDateParam: undefined,
  checkOutDateParam: undefined,
  protectionValue: 0,
  bookingDetails: {},
  selectedCurrency: "",
  currChangeVal: 1,
  slotType: "THREE_SLOTS",
  MLOS: 1,
  paymentFailObjData: {},
  colorConfig: {},
  pageAmpLoaded: 0,
  partialPaymentActive: "DISABLED",
  partialPaymentPerc: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PARTIAL_PAYMENT":
      localStorage.setItem("partialPaymentActive", action.partialPaymentActive);
      localStorage.setItem("partialPaymentPerc", action.value);
      return {
        ...state,
        partialPaymentActive: action.partialPaymentActive,
        partialPaymentPerc: action.partialPaymentPerc,
      };
    case "UPDATE_AMPLITUDE_LOADER":
      localStorage.setItem("pageAmpLoaded", action.value);
      return {
        ...state,
        pageAmpLoaded: action.value,
      };
    case "SET_PAYMENT_FAIL_OBJ":
      localStorage.setItem("paymentFailObj", JSON.stringify(action.paymentObj));
      return {
        ...state,
        paymentFailObjData: action.paymentObj,
      };
    case "SET_COLOR_CONFIG":
      localStorage.setItem("colorConfig", JSON.stringify(action.colorConfig));
      return {
        ...state,
        colorConfig: action.colorConfig,
      };
    case "SET_CURR_VAL":
      localStorage.setItem("currChangeVal", action.currChangeVal);
      return {
        ...state,
        currChangeVal: action.currChangeVal,
      };
    case "SET_SELECTED_CURRENCY":
      localStorage.setItem("selectedCurrency", action.selectedCurrency);
      return {
        ...state,
        selectedCurrency: action.selectedCurrency,
      };
    case "SET_TEMP_BOOKING_DETAILS":
      localStorage.setItem(
        "bookingDetails",
        JSON.stringify(action.bookingDetails)
      );
      return {
        ...state,
        bookingDetails: action.bookingDetails,
      };
    case "SET_PROTECTION_VALUE":
      localStorage.setItem("protectionValue", action.protectionValue);
      return {
        ...state,
        protectionValue: action.protectionValue,
      };
    case "SET_CHECK_IN_DATE_UPDATED":
      return {
        ...state,
        checkInDateParam: action.checkInDate,
      };

    case "SET_CHECK_OUT_DATE_UPDATED":
      return {
        ...state,
        checkOutDateParam: action.checkOutDate,
      };
    case "SET_CHECK_IN_OUT_DATES":
      return {
        ...state,
        checkInDateParam: action.checkInDate,
        checkOutDateParam: action.checkOutDate,
      };

    case "USER_INFO":
      return {
        ...state,
        userData: action.userData,
      };
    case "ADD_ADDON":
      if (state.addOns?.length != undefined && state.addOns?.length > 0) {
        let index = state.addOns.findIndex((x) => x.product == action.addOn);
        if (index == -1) {
          let tempAddOn = {
            product: action.addOn,
            shop_id: action.shop_id,
            qty: 1,
          };
          localStorage.setItem(
            "addOns",
            JSON.stringify([...state.addOns, tempAddOn])
          );
          return {
            ...state,
            addOns: [...state.addOns, tempAddOn],
          };
        }
        let tempAddOn = {
          product: action.addOn,
          shop_id: action.shop_id,
          qty: state.addOns[index].qty + 1,
        };
        let tempAddonArray = [...state.addOns];
        tempAddonArray[index] = tempAddOn;
        localStorage.setItem("addOns", JSON.stringify(tempAddonArray));
        return {
          ...state,
          addOns: [...tempAddonArray],
        };
      } else {
        let tempAddOn = {
          product: action.addOn,
          shop_id: action.shop_id,
          qty: 1,
        };
        localStorage.setItem("addOns", JSON.stringify([tempAddOn]));
        return {
          ...state,
          addOns: [tempAddOn],
        };
      }
    case "REMOVE_ADDON":
      localStorage.setItem("addOns", JSON.stringify(action.addonUpdatedData));
      return {
        ...state,
        addOns: action.addonUpdatedData,
      };
    case "UPDATE_ADD_ON":
      localStorage.setItem("addOns", JSON.stringify(action.updatedAddOns));
      return {
        ...state,
        addOns: action.updatedAddOns,
      };

    case "SET_CUSTOM_PG_INFO":
      return {
        ...state,
        customPgInfo: action.customPgInfo,
      };

    case "SET_HOTEL_ID":
      localStorage.setItem("bookingEngineHotelId", action.hotelId);
      return {
        ...state,
        hotelId: action.hotelId,
      };
    case "SET_BOOKING_SOURCE":
      localStorage.setItem("bookingSource", action.bookingSource);
      return {
        ...state,
        bookingSource: action.bookingSource,
      };
    case "FINAL_CONFIRMATION_DETAILS":
      localStorage.setItem(
        "finalBookingData",
        JSON.stringify(action.finalBookingData)
      );
      localStorage.setItem("bookingId", action.bookingId);
      localStorage.setItem(
        "finalBookingAmount",
        JSON.stringify({
          amount: state.amount,
          taxes: state.taxes,
          finalAmount: state.finalAmount,
        })
      );
      return {
        ...state,
        finalBookingData: action.finalBookingData,
        bookingId: action.bookingId,
      };

    case "BOOKING_DATA":
      localStorage.setItem("bookingData", JSON.stringify(action.bookingData));
      return {
        ...state,
        bookingData: action.bookingData,
      };

    case "HOTEL_DATA":
      localStorage.setItem("hotelData", JSON.stringify(action.hotelData));
      return {
        ...state,
        hotelData: action.hotelData,
      };

    case "SET_FINAL_AMOUNT_PROTECTION":
      return {
        ...state,
        finalAmount:
          state.amount +
          state.taxes +
          state.addonamount +
          action.protectionValue,
      };
    case "SET_FINAL_AMOUNT":
      return {
        ...state,
        finalAmount:
          state.amount +
          state.taxes +
          state.addonamount +
          state.protectionValue,
      };
    case "ADD_ADDON_AMOUNT":
      return {
        ...state,
        addonamount: state.addonamount + action.addonamount,
      };

    case "SET_ADDON_AMOUNT":
      let tempAddOn = 0;
      for (let i = 0; i < state?.addOns?.length; i++) {
        tempAddOn =
          state.addOns[i].product.product_price * state.addOns[i].qty +
          tempAddOn;
      }
      return {
        ...state,
        addonamount: tempAddOn,
      };

    case "SET_ADDON_TAXES":
      let tempAddOnTaxes = 0;
      for (let i = 0; i < state?.addOns?.length; i++) {
        let addOnTax = state.addOns[i].product.tax_category.percentage;
        tempAddOnTaxes =
          tempAddOnTaxes +
          (state.addOns[i].product.product_price *
            state.addOns[i].qty *
            addOnTax) /
            100;
      }
      let updatedTax = state.taxes - state.addontaxes + tempAddOnTaxes;
      return {
        ...state,
        addontaxes: tempAddOnTaxes,
        taxes: updatedTax,
      };
    case "SET_AMOUNT_TAXES":
      return {
        ...state,
        amount: state.amount + action.amount,
        taxes: state.taxes + action.tax,
      };
    case "RESET_ADDON_AMOUNT":
      return {
        ...state,
        addonamount: state.addonamount - action.addonamount,
      };
    case "RESET_AMOUNT_TAXES":
      return {
        ...state,
        amount: 0,
        taxes: 0,
      };
    case "UPDATE_ROOM_STAYS":
      localStorage.setItem("roomStays", JSON.stringify(action.updatedRoomData));
      return {
        ...state,
        roomStays: action.updatedRoomData,
      };
    case "ADD_ROOM_STAYS":
      localStorage.setItem(
        "roomStays",
        JSON.stringify([...state.roomStays, action.roomData])
      );
      return {
        ...state,
        roomStays: [...state.roomStays, action.roomData],
      };
    case "REMOVE_ROOM_STAYS":
      localStorage.setItem("roomStays", JSON.stringify(action.roomUpdatedData));
      return {
        ...state,
        roomStays: action.roomUpdatedData,
      };

    case "NEW_API_PARAMETERS":
      return {
        ...state,
        apiParameters: action.parameters,
      };

    case "USER_FLEXIBLE":
      return {
        ...state,
        flexibleToggle: action.flexible,
      };
    case "ROOM_TYPE_COUNT":
      return {
        ...state,
        roomTypeData: [...state.roomTypeData, action.roomTypeData],
      };
    case "UPDATE_ROOM_TYPE_COUNT":
      return {
        ...state,
        roomTypeData: action.roomTypeData,
      };
    case "PAY_AT_HOTEL":
      localStorage.setItem("payAtHotel", action.payAtHotel);
      return {
        ...state,
        payAtHotel: action.payAtHotel,
      };
    case "PROMO_CODE_DATA":
      return {
        ...state,
        promoCodeData: action.promoCodeData,
        promoCode: action.promoCode,
      };
    case "SET_CLIENT_SECRET":
      return {
        ...state,
        clientSecret: action.clientSecret,
      };
    case "BOOKING_PAYLOAD_ID":
      return {
        ...state,
        bookingPayloadId: action.bookingPayloadId,
      };
    case "SET_SLOT_MODEL":
      localStorage.setItem("slotType", action.slotType);
      return {
        ...state,
        slotType: action.slotType,
      };
    case "SET_MLOS":
      localStorage.setItem("MLOS", action.MLOS);
      return {
        ...state,
        MLOS: action.MLOS,
      };
    default:
      return state;
  }
};

export default reducer;
