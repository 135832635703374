import dotenv from "dotenv";
dotenv.config();
export const baseUrl = process.env.REACT_APP_API_URL;
export const baseUrlDjango = process.env.REACT_APP_API_DJANGO_URL;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const razorPayKey = process.env.REACT_APP_RAZORPAY_PUBLIC_KEY;
export const ENV = process.env.REACT_APP_ENV;
export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
export const PROTECT_GROUP_KEY = process.env.REACT_APP_PROTECT_GROUP_VENDOR_KEY;
export const selfServiceUrl = process.env.REACT_APP_SELF_SERVICE_URL;
