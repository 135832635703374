import axios from "axios";
import { format } from "date-fns";
import { countriescodename } from "./countrynamecodemap";
import { baseUrl } from "./constants";
import Currencies from "../utils/Currencies.json";
import moment from "moment";

const sources_gha_or_ota = [
  "bookflexi",
  "google",
  "sfgoogle",
  "stayflexi",
  "tripadvisor",
  "OTA",
  "BE",
];
const google_date_format = "dd-MM-yyyy";
export function handleTimeConvert(time) {
  const hour = Number(time?.split(":")[0]);
  let h = hour % 12 || 12;
  let amPm = hour < 12 || hour === 24 ? "AM" : "PM";
  return h + " " + amPm;
}

//apart from GHA any format is MM-DD-YYYYY

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

//Updated function for url parameter
export function checkInDateParams(params) {
  let date;
  if (params["checkin"] != undefined) {
    date = params["checkin"];
  }
  return date;
}

export function checkOutDateParams(params) {
  let date;
  if (params["checkout"] != undefined) {
    date = params["checkout"];
  }
  return date;
}

export function checkInDate(params) {
  let date;
  if (params["checkin"] !== undefined) {
    // if (sources_gha_or_ota.includes(params["source"])) {
    //   date = params["checkin"];
    // } else {
    let currentDate = new Date();
    // currentDate = new Date(
    //   currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
    // );
    let tempDate = new Date(params["checkin"]?.split("-").reverse().join("-"));
    if (isNaN(tempDate.getTime())) {
      let allDateParams = params["checkin"]?.split("-");
      let updatedDateFormat =
        allDateParams[2] + "-" + allDateParams[0] + "-" + allDateParams[1];
      tempDate = new Date(updatedDateFormat);
    }
    // tempDate = new Date(
    //   tempDate.valueOf() + tempDate.getTimezoneOffset() * 60 * 1000
    // );
    if (tempDate < currentDate) {
      date = format(new Date(), google_date_format);
    } else {
      date = format(tempDate, google_date_format);
    }
    // }
  } else if (params["checkout"] !== undefined) {
    // if (sources_gha_or_ota.includes(params["source"])) {
    //   date = format(
    //     new Date(params["checkout"]?.split("-").reverse().join("-")).setDate(
    //       new Date(
    //         params["checkout"]?.split("-").reverse().join("-")
    //       ).getDate() -
    //         (params["num_nights"] ? Number(params["num_nights"]) : 1)
    //     ),
    //     google_date_format
    //   );
    // } else {
    let tempDate = new Date(params["checkout"]?.split("-").reverse().join("-"));
    if (isNaN(tempDate.getTime())) {
      let allDateParams = params["checkout"]?.split("-");
      let updatedDateFormat =
        allDateParams[2] + "-" + allDateParams[0] + "-" + allDateParams[1];
      tempDate = new Date(updatedDateFormat);
    }
    // tempDate = new Date(
    //   tempDate.valueOf() + tempDate.getTimezoneOffset() * 60 * 1000
    // );
    let currentDate = new Date(new Date().setDate(new Date().getDate() + 1));
    // currentDate = new Date(
    //   currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
    // );
    if (tempDate < currentDate) {
      date = format(currentDate, google_date_format);
    } else {
      date = format(
        tempDate.setDate(
          tempDate.getDate() -
            (params["num_nights"] ? Number(params["num_nights"]) : 1)
        ),
        google_date_format
      );
    }
    // }
  } else {
    // let currentDate = new Date();
    // currentDate = new Date(
    //   currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
    // );
    date = format(new Date(), google_date_format);
  }
  return date;
}

export function checkOutDate(params) {
  let date;
  if (params["checkout"] !== undefined) {
    // if (sources_gha_or_ota.includes(params["source"])) {
    //   date = params["checkout"];
    // } else {
    let currentDate = new Date(new Date().setDate(new Date().getDate() + 1));
    // currentDate = new Date(
    //   currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
    // );
    let tempDate = new Date(params["checkout"]?.split("-").reverse().join("-"));
    if (isNaN(tempDate.getTime())) {
      let allDateParams = params["checkout"]?.split("-");
      let updatedDateFormat =
        allDateParams[2] + "-" + allDateParams[0] + "-" + allDateParams[1];
      tempDate = new Date(updatedDateFormat);
    }
    // tempDate = new Date(
    //   tempDate.valueOf() + tempDate.getTimezoneOffset() * 60 * 1000
    // );
    if (tempDate < currentDate) {
      date = format(currentDate, google_date_format);
    } else {
      date = format(tempDate, google_date_format);
    }
    // }
  } else if (params["checkin"] !== undefined) {
    // if (sources_gha_or_ota.includes(params["source"])) {
    //   date = format(
    //     new Date(params["checkin"]?.split("-").reverse().join("-")).setDate(
    //       new Date(
    //         params["checkin"]?.split("-").reverse().join("-")
    //       ).getDate() +
    //         (params["num_nights"] ? Number(params["num_nights"]) : 1)
    //     ),
    //     google_date_format
    //   );
    // } else {
    let tempDate = new Date(params["checkin"]?.split("-").reverse().join("-"));
    if (isNaN(tempDate.getTime())) {
      let allDateParams = params["checkin"]?.split("-");
      let updatedDateFormat =
        allDateParams[2] + "-" + allDateParams[0] + "-" + allDateParams[1];
      tempDate = new Date(updatedDateFormat);
    }
    // tempDate = new Date(
    //   tempDate.valueOf() + tempDate.getTimezoneOffset() * 60 * 1000
    // );
    let currentDate = new Date();
    // currentDate = new Date(
    //   currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
    // );
    if (tempDate < currentDate) {
      date = format(currentDate, google_date_format);
    } else {
      tempDate.setDate(
        tempDate.getDate() +
          (params["num_nights"] ? Number(params["num_nights"]) : 1)
      );
      // tempDate = new Date(
      //   tempDate.valueOf() + tempDate.getTimezoneOffset() * 60 * 1000
      // );
      date = format(tempDate, google_date_format);
      console.log("date: ", date);
    }
    // }
  } else {
    date = format(
      new Date().setDate(
        new Date().getDate() +
          (params["num_nights"] ? Number(params["num_nights"]) : 1)
      ),
      google_date_format
    );
  }
  return date;
}

export const countries = countriescodename.map((item, index) => {
  return item.Code;
});

export function nameCode(name, entryname) {
  const code = countriescodename.filter(
    (i, k) => i.Name === entryname || i.Name === name
  )[0].Code;
  return code;
}
export const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64,${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64,${img}`;
  }
};

export const getCurrencySymbol = (code) => {
  let currencySymbol = "";
  Currencies?.filter((item) => {
    if (item.code === code) {
      currencySymbol = item.symbol;
    }
  });
  return currencySymbol;
};

//Mobile screen width setting
export const mobile_screen_width = 440;

export const channelNameConversion = {
  google: "Direct Rate",
  sfgoogle: "Bookflexi",
  booking: "Booking.com",
  expedia: "Expedia.com",
  goibibo: "Goibibo.com",
  yatra: "Yatra.com",
  agoda: "Agoda.com",
  hostelworld: "Hostelworld.com",
  airbnb: "airbnb.com",
};

let firstPath = "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/";
export const channelLogoMapDash = {
  "Direct Rate": firstPath + "favicon/sfstickers.png",
  Bookflexi: firstPath + "favicon/sfstickers.png",
  "Booking.com": firstPath + "favicon/booking.png",
  "Expedia.com": firstPath + "favicon/expedia.png",
  "Goibibo.com": firstPath + "favicon/goibibo.png",
  "Yatra.com": firstPath + "favicon/yatra.png",
  "Agoda.com": firstPath + "favicon/agoda.png",
  "Hostelworld.com": firstPath + "favicon/hostelworld.png",
  "airbnb.com": firstPath + "favicon/airbnb.png",
};

export const channelLogoMap = {
  jasperrooms: firstPath + "jasperlogo.png",
  HOSTEL_WORLD: firstPath + "hostelworld.png",
  //----Added By Himanshu----
  HOSTELWORLD: firstPath + "hostelworld.png",
  TRIPADVISOR: firstPath + "tripadvisor.png",
  TRIVAGO: firstPath + "trivago.png",
  HOTELBEDS: firstPath + "hotelbeds.png",
  HAPPYEASYGO: firstPath + "happyeasygo.png",
  TRAVELGURU: firstPath + "travelguru.png",
  GETAROOM: firstPath + "getaroom.jpeg",
  getaroom: firstPath + "getaroom.jpeg",
  BOOKING: firstPath + "bookingcom.png",
  "BOOKING.COM": firstPath + "bookingcom.png",
  "BOOKING.com": firstPath + "bookingcom.png",
  "GO-MMT": firstPath + "go-mmt.jpg",
  MAKEMYTRIP: firstPath + "makemytrip.png",
  GOIBIBO: firstPath + "goibibo.png",
  GOOMO: firstPath + "goomo.png",
  YATRA: firstPath + "yatra.png",
  CLEARTRIP: firstPath + "cleartrip.jpeg",
  AGODA: firstPath + "agoda.png",
  TRAVELOKA: firstPath + "traveloka.png",
  EASEMYTRIP: firstPath + "easemytrip.jpg",
  CTRIP: firstPath + "ctrip.jpg",
  PAYTM: firstPath + "paytm.jpeg",
  VIA: firstPath + "via_logo.jpg",
  "VIA.COM": firstPath + "via_logo.jpg",
  TIKET: firstPath + "tiket.png",
  "TIKET.COM": firstPath + "tiket.png",
  AIRBNB: firstPath + "airbnb.png",
  PRICELINE: firstPath + "priceline.png",
  "PRICELINE.COM": firstPath + "priceline.png",
  TRAVELOCITY: firstPath + "travelocity-logo-vector.png",
  "A-TRAVELOCITY": firstPath + "travelocity-logo-vector.png",
  ORBITZ: firstPath + "orbitz.png",
  "A-ORBITZ": firstPath + "orbitz.png",
  EXPEDIA: firstPath + "expedia.png",
  "A-EXPEDIA": firstPath + "expedia.png",
  "EXPEDIA AFFILIATE NETWORK": firstPath + "expedia_affiliate.png",
  "A-EXPEDIA AFFILIATE NETWORK": firstPath + "expedia_affiliate.png",
  EBOOKERS: firstPath + "ebookers-com-vector-logo.png",
  "A-EBOOKERS": firstPath + "ebookers-com-vector-logo.png",
  WOTIF: firstPath + "logo-wotif.png",
  "WOTIF.COM": firstPath + "logo-wotif.png",
  "A-WOTIF": firstPath + "logo-wotif.png",
  HOTWIRE: firstPath + "hotwire.png",
  "HOTWIRE.COM": firstPath + "hotwire.png",
  "A-HOTWIRE": firstPath + "hotwire.png",
  EGENCIA: firstPath + "logo-egencia-brand.png",
  "A-EGENCIA": firstPath + "logo-egencia-brand.png",
  "THOMAS COOK": firstPath + "logo-thomas-cook.png",
  "A-THOMAS COOK": firstPath + "logo-thomas-cook.png",
  "HOTELS.COM": firstPath + "Hotels.com_logo.png",
  "A-HOTELS.COM": firstPath + "Hotels.com_logo.png",
  HOTELS: firstPath + "Hotels.com_logo.png",
  CHEAPTICKETS: firstPath + "cheaptickets-01.jpg",
  "CHEAPTICKETS.COM": firstPath + "cheaptickets-01.jpg",
  "A-CHEAPTICKETS": firstPath + "cheaptickets-01.jpg",
  STAYFLEXI_HD: firstPath + "favicon/walkin.png",
  "WALK-IN": firstPath + "favicon/walkin.png",
  "Walk-In": firstPath + "favicon/walkin.png",
  STAYFLEXI_OD: firstPath + "sflogos.png",
  STAYFLEXI_OM: firstPath + "sflogos.png",
  "STAYFLEXI MOBILE": firstPath + "sflogos.png",
  STAYFLEXI_GHA: firstPath + "sflogos.png",
  STAYFLEXI_BE: firstPath + "sflogos.png",
  STAYFLEXI_MOBILE: firstPath + "sflogos.png",
};

export const SFLogoPng = firstPath + "sflogos.png";

export const STRIPE_SEPARATE_CHARGE_TYPE = "SEPARATE_CHARGE";
export const STRIPE_DIRECT_CHARGE_TYPE = "DIRECT_CHARGE";
